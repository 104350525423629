
const getCategories = (categoriesData) => {
    let categories = [];
    if (categoriesData && categoriesData.edges) {
        categoriesData.edges.forEach(el => {
            if (el.node) categories.push(el.node);
        });
    }

    return categories;
};

const getSubCategories = (subCategoriesData) => {
    let subCategories = [];
    if (subCategoriesData && subCategoriesData.edges) {
        subCategoriesData.edges.forEach(el => {
            if (el.node) subCategories.push(el.node);
        });
    }

    return subCategories;
};

module.exports = {
    getCategories,
    getSubCategories
};