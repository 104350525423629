// Libs
import React from "react";

//import { Link } from 'gatsby';
import PropTypes from "prop-types";

// CSS
import "./shop-now.scss";

export const ShopNow = (props) => {
    const label = props && props.label;
    const componentClasses = props && props.componentClass ? `c-shop-now-button ${props.componentClass}` : "c-shop-now-button";
    const buttonClasses = props && props.buttonClass ? `c-shop-now-button__link ${props.buttonClass}` : "c-shop-now-button__link";
    const containerClasses = props && props.containerClass ? `c-shop-now-button__container ${props.containerClass}` : "c-shop-now-button__container";

    return (
        <div className={componentClasses}>
            <div className={containerClasses}>
                <div className={buttonClasses} aria-label={label}>
                    {props && props.children}
                </div>
            </div>
        </div>
    );
};

// PropTypes
ShopNow.propTypes = {
    label: PropTypes.string,
    text: PropTypes.string,
    componentClass: PropTypes.string,
    buttonClass: PropTypes.string,
    children: PropTypes.any
};