import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    ...SiteInformation
                }
            }
        `
    );

    return site.siteMetadata;
}
