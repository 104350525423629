const getLanguageRegionPath = (languageRegion, path) => {
    if (path === "" || path === undefined) return languageRegion === "en-us" ? "/" : `/${languageRegion}`;

    return languageRegion === "en-us" ? path : `/${languageRegion}${path}`;
};

const getProductFilterPath = (productFilters) => {
    const productFilterPathParams = productFilters && productFilters.length > 0 && productFilters.map(productFilter => {
        if (productFilter && productFilter.queryStringParam && productFilter.value) {
            return `${productFilter.queryStringParam}=${productFilter.value}`;
        } else {
            return null;
        }
    }).filter(e => e);

    if (productFilterPathParams && productFilterPathParams.length > 0) {
        return `?${productFilterPathParams.join("&")}`;
    } else {
        return "";
    }
};

const getPath = (languageRegion, path = undefined, categorySlug = undefined, subCategorySlug = undefined, filters = undefined, pageNumber = undefined) => {
    const productFilters = filters && filters.length > 0 ? filters.filter(e => e) : null;

    // generate new filter url
    const pathPath = path ? `/${path}` : "";
    const categoryPath = categorySlug ? (subCategorySlug ? `/${categorySlug}/${subCategorySlug}` : `/${categorySlug}`) : "";
    const productFilterPath = productFilters && productFilters.length > 0 ? getProductFilterPath(productFilters) : "";
    const pageNumberPath = pageNumber ? `/${pageNumber}` : "";

    return getLanguageRegionPath(languageRegion, `${categoryPath}${pathPath}${pageNumberPath}${productFilterPath}`);
};

module.exports = {
    getPath
};