import React from "react";

import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import { getLink } from './get-link';
import { getMeta } from './get-meta';
import { getScript } from './get-script';
import { getTitle } from './get-title';

export const HelmetHelper = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    site {
                        siteMetadata {
                            siteUrl
                            supportedLanguageRegions
                        }
                    }
                }
            `}
            render={data => {
                return <Helmet
                    title={getTitle(props.title)}
                    meta={getMeta(props.meta)}
                    script={getScript(props.script)}
                    link={getLink(
                        {
                            siteUrl: data.site.siteMetadata.siteUrl,
                            category: props.category,
                            subCategory: props.subCategory,
                            currentPage: props.currentPage,
                            numPages: props.numPages,
                            currentLanguageRegion: props.currentLanguageRegion,
                            languageRegions: data.site.siteMetadata.supportedLanguageRegions,
                            urlPath: props.urlPath
                        }
                    )}
                />
            }}
        />
    )
}