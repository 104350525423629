import { changeLanguageRegionPath } from '../context-providers/language-region-context';

export const getLink = (
    { siteUrl, category, subCategory, currentPage, numPages, currentLanguageRegion, languageRegions, urlPath }
) => {

    let links = [];
    const languageRegion = currentLanguageRegion === "en-us" ? `` : `/${currentLanguageRegion}`;

    if (siteUrl && urlPath && !currentPage) {
        const pageLink = { rel: 'canonical', href: `${siteUrl}${urlPath}` };
        links.push(pageLink);
    }

    if (category && subCategory && currentPage) {
        // Canonical Links for SEO - Current Page
        if (currentPage === 1) {
            const pageLink = { rel: 'canonical', href: `${siteUrl}${languageRegion}/${category.slug}/${subCategory.slug}` };
            links.push(pageLink);
        } else {
            const pageLink = { rel: 'canonical', href: `${siteUrl}${languageRegion}/${category.slug}/${subCategory.slug}/${currentPage}` };
            links.push(pageLink);
        }

        // Canonical Links for SEO - Next Page

        if (currentPage < numPages) {
            const nextLink = { rel: 'next', href: `${siteUrl}${languageRegion}/${category.slug}/${subCategory.slug}/${currentPage + 1}` };
            links.push(nextLink);
        }

        // Canonical Links for SEO - Previous Page
        if (currentPage !== 1 && currentPage !== 2) {
            const previousLink = { rel: 'prev', href: `${siteUrl}${languageRegion}/${category.slug}/${subCategory.slug}/${currentPage - 1}` };
            links.push(previousLink);
        }
        if (currentPage === 2) {
            const previousLink = { rel: 'prev', href: `${siteUrl}${languageRegion}/${category.slug}/${subCategory.slug}` };
            links.push(previousLink);
        }
    }

    if (category && currentPage && !subCategory) {
        // Canonical Links for SEO - Current Page
        if (currentPage === 1) {
            const pageLink = { rel: 'canonical', href: `${siteUrl}${languageRegion}/${category.slug}` };
            links.push(pageLink);
        } else {
            const pageLink = { rel: 'canonical', href: `${siteUrl}${languageRegion}/${category.slug}/${currentPage}` };
            links.push(pageLink);
        }

        // Canonical Links for SEO - Next Page

        if (currentPage < numPages) {
            const nextLink = { rel: 'next', href: `${siteUrl}${languageRegion}/${category.slug}/${currentPage + 1}` };
            links.push(nextLink);
        }

        // Canonical Links for SEO - Previous Page
        if (currentPage !== 1 && currentPage !== 2) {
            const previousLink = { rel: 'prev', href: `${siteUrl}${languageRegion}/${category.slug}/${currentPage - 1}` };
            links.push(previousLink);
        }
        if (currentPage === 2) {
            const previousLink = { rel: 'prev', href: `${siteUrl}${languageRegion}/${category.slug}` };
            links.push(previousLink);
        }
    }

    if (siteUrl && languageRegions && urlPath)
        languageRegions.forEach((languageRegion) => {
            const path = changeLanguageRegionPath({ pathname: urlPath, newLanguageRegion: languageRegion, supportedLanguageRegions: languageRegions })
            const newPath = `${siteUrl}/${path}`;

            const altHrefLangLink = { rel: 'alternate', href: newPath, hreflang: languageRegion };
            links.push(altHrefLangLink);
        })

    return links;
}