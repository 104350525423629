import React from "react";

import { Link } from "gatsby";

import { getPath } from "../../helpers/get-path";

export const CompanyInfo = (props) => {
    return (
        <nav>
            <ul className="c-footer__list" aria-labelledby="footer-col3">
                { props.releaseStage > 2 && <li className="c-footer__list-item c-footer__list-item--active-arrow"><Link to={getPath(props.languageRegion, "articles")}>Articles</Link></li> }
                <li className="c-footer__list-item c-footer__list-item--active-arrow"><Link to={getPath(props.languageRegion, "about-us")}>About Us</Link></li>
                <li className="c-footer__list-item c-footer__list-item--active-arrow"><Link to={getPath(props.languageRegion, "contact-us")}>Contact Us</Link></li>
                <li className="c-footer__list-item c-footer__list-item--active-arrow"><Link to={getPath(props.languageRegion, "affiliate-disclosure")}>Affiliate Disclosure</Link></li>
                <li className="c-footer__list-item c-footer__list-item--active-arrow"><a href="https://github.com/LpmRaven">Developers</a></li>
            </ul>
        </nav>
    );
};

