
// Libs
import React from "react";

import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";

import { Title } from "../title";

// CSS
import "./logo.scss";

export const Logo = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    site {
                        siteMetadata {
                            siteName
                            siteSlogan
                        }
                    }
                }
            `}
            render={data => {
                const siteSlogan = (data && data.site && data.site.siteMetadata && data.site.siteMetadata.siteSlogan) || "";

                return (
                    <div className={props.darkBackground ? "c-logo c-logo--background" : "c-logo"} id="title" >
                        <div className="g-flex__container g-flex__container--column c-logo__full-title">
                            {siteSlogan &&
                                <>
                                    <span className="g-flex__item c-logo__sub-title-text u-visually-hidden">{siteSlogan}</span>
                                    <span className="g-flex__item u-visually-hidden c-logo__spacer u-visually-hidden"> - </span>
                                </>
                            }
                            <Title containerClassName="g-flex__item" darkBackground={props.darkBackground} />
                        </div>
                    </div>
                );
            }}
        />
    );
};

// PropTypes
Title.propTypes = {
    navClass: PropTypes.string
};
