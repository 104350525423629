import React from "react";

import { graphql, StaticQuery } from "gatsby";

// CSS
import "./title.scss";

export const Title = (props) => {
    return (
        <StaticQuery
            query={graphql`
            query {
                site {
                    siteMetadata {
                        siteName
                    }
                }
            }
        `}
            render={data => {
                const siteName = props.title ? props.title : (data && data.site && data.site.siteMetadata && data.site.siteMetadata.siteName) || "Product Jack";
                const siteNameWords = siteName.split(" ");
                const containerClassName = `c-title ${props.containerClassName} g-flex__container g-flex__container--no-wrap g-flex__container--align-center`;

                const titleClasses = props.darkBackground ? "primary-font g-flex__item c-title__title-text--background" : "primary-font g-flex__item c-title__title-text";

                return (
                    <div className={containerClassName}>
                        {siteNameWords[0] && <span className={titleClasses} style={props.styles}>{siteNameWords[0]}</span>}
                        {siteNameWords[1] && <div className="primary-font g-flex__item c-title__title-text--secondary"><span>{siteNameWords[1]}</span></div>}
                        {siteNameWords[2] && <div className="primary-font g-flex__item c-title__title-text--secondary"><span>{siteNameWords[2]}</span></div>}
                    </div>
                );
            }}
        />
    );
};