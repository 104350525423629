import React from "react";

import { Link , graphql, StaticQuery } from "gatsby";

import { getPath } from "../../helpers/get-path";

const priorityOrder = (arr) => {
    return arr.sort((a, b) => parseFloat(a.node.priority) - parseFloat(b.node.priority));
};

export const ProductsList = ({ props: { languageRegion } }) => {
    return (
        <StaticQuery
            query={graphql`
                query ProductsListQuery {
                    allCategory {
                        edges {
                            node {
                                ...Categorys
                            }
                        }
                    }
                    allSubCategory {
                        edges {
                            node {
                                ...SubCategorys
                            }
                        }
                    }
                }
            `}
            render={data => {
                const categories = data && data.allCategory && data.allCategory.edges && data.allCategory.edges && priorityOrder(data.allCategory.edges);
                const subCategories = data && data.allSubCategory && data.allSubCategory.edges && data.allSubCategory.edges && priorityOrder(data.allSubCategory.edges);

                return (
                    <nav>
                        <ul className="c-footer__list g-flex__container" aria-labelledby="footer-col1">
                            {categories.map((categoryNode) => {
                                const category = categoryNode.node;

                                return (subCategories.map((subCategoryNode) => {
                                    const subCategory = subCategoryNode.node;
                                    if (subCategory.parentCategoryIds.some((parentCategoryId) => parentCategoryId === category.categoryId)) {
                                        return (
                                            <li key={`${category.slug}-${subCategory.slug}-product-list`} className="c-footer__list-item c-footer__list-item--active-arrow g-flex__item"><Link to={getPath(languageRegion, undefined, category.slug, subCategory.slug)}>{category.formattedName} {subCategory.formattedName}</Link></li>
                                        );
                                    }
                                }));
                            })}
                        </ul>
                    </nav>
                );
            }
            }
        />
    );
};


