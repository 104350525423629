import React from "react";

// import { IoIosHeart } from 'react-icons/io';

import { useSiteMetadata } from "../../hooks/use-site-metadata";

import "./copyright.scss";

export const Copyright = () => {
    const { siteName } = useSiteMetadata() || "Product Jack";
    const copyrightText = [
        `Copyright © 2022 ${siteName}.`,
        " Created ",
        // <IoIosHeart key="love" style={{ verticalAlign: 'bottom' }} size={24} />,
        // " by Product Jack",
        " in Sydney, Australia."
    ];

    return (
        <div className="c-copyright g-grid__item--footer-copyright c-footer__copyright-wrapper">
            <div className="g-grid__container--copyright-layout">
                <div className="g-grid__item--content-pl"></div>
                <div className="g-grid__item--content-main">
                    <div className="g-flex__container g-flex__container--align-center g-flex__container--no-wrap">
                        <p className="c-footer__copyright g-flex__item">{copyrightText}</p>
                        {/* <button className="c-footer__back-to-top g-flex__item g-flex__item--right">Back to top</button> */}
                    </div>
                </div>
                <div className="g-grid__item--content-pr"></div>
            </div>
        </div>
    );
};
