// Libs
import React from "react";

import { Link, graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";

import { getPath } from "../../helpers/get-path";

// CSS
import "./navigation.scss";
import "./hamburger.scss";

const isPartiallyActive = ({ isPartiallyCurrent }) =>
    isPartiallyCurrent ? { className: "item active" } : null;

const checkLanguageRegionMatchesConfig = (languageRegions, newLanguageRegion) => {
    return languageRegions.includes(newLanguageRegion);
};

const isActiveHome = ({ pathname, languageRegions }) => {
    const path = pathname.split("/");

    const isHome = !!path[1] === false || checkLanguageRegionMatchesConfig(languageRegions, path[1]);

    if (path.length > 2) {
        return null;
    }

    return isHome ? { className: "item active" } : null;
};

const priorityOrder = (arr) => {
    return arr.sort((a, b) => parseFloat(a.node.priority) - parseFloat(b.node.priority));
};

const NavigationComponent = ({ className, isMenuOpen, isMobile, categories, subCategories, languageRegions, languageRegion, background, darkBackground, releaseStage }) => {
    const isMenuOpenClassNames = isMenuOpen ? "c-navigation__nav--open" : "c-navigation__nav--closed";
    const backgroundClass = background ? "c-navigation--background" : "";
    const textColor = background && !darkBackground ? "c-navigation--darkText" : "c-navigation--whiteText";
    const componentClassNames = isMobile ? `${backgroundClass} c-navigation--mobile` : `${backgroundClass} c-navigation--desktop`;
    const topNavClassNames = isMobile ? "topnav--mobile g-flex__container g-flex__container--column-always" : "topnav g-flex__container g-flex__container--no-wrap";
    const subNavClassNames = isMobile ? "subnav--mobile g-flex__container g-flex__container--column-always" : "subnav g-flex__container g-flex__container--column";
    const navClassNames = isMobile ? isMenuOpenClassNames : "";
    const navigationId = isMobile ? "navigation-mobile" : "navigation-desktop";

    return (
        <div className={`c-navigation ${className}`}>
            <div className={`${componentClassNames} ${textColor}`}>
                <nav id={navigationId} className={navClassNames}>
                    <ul className={topNavClassNames}>
                        <li className="g-flex__item">
                            <Link to={getPath(languageRegion)} getProps={(nav) => isActiveHome({ pathname: nav.location.pathname, languageRegions: languageRegions })} activeClassName="active">Home</Link>
                        </li>
                        {releaseStage === 2 &&
                            <>
                                <li className="g-flex__item">
                                    <a href={getPath(languageRegion, "#new-features")}>Features</a>
                                </li>
                                <li className="g-flex__item">
                                    <a href={getPath(languageRegion, "#founder-message")}>About</a>
                                </li>
                            </>
                        }
                        {releaseStage > 2 && categories.map((categoryNode) => {
                            const category = categoryNode.node;

                            return (
                                <li key={`${category.slug}-navigation`} className={"g-flex__item"}>
                                    <Link to={getPath(languageRegion, undefined, category.slug)} getProps={isPartiallyActive} activeClassName="active">{category.formattedName}</Link>
                                    <ul className={subNavClassNames}>
                                        {subCategories.map((subCategoryNode) => {
                                            const subCategory = subCategoryNode.node;
                                            if (subCategory.parentCategoryIds.some((parentCategoryId) => parentCategoryId === category.categoryId)) {
                                                return (
                                                    <li key={`${category.slug}-${subCategory.slug}-navigation`} className="g-flex__item">
                                                        <Link to={getPath(languageRegion, undefined, category.slug, subCategory.slug)} getProps={isPartiallyActive} activeClassName="active">{subCategory.formattedName}</Link>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export const NavigationButton = ({className, isMenuOpen, isMobile, onToggleMenu, background }) => {
    const buttonClassNames = isMenuOpen ? "c-navigation__button hamburger hamburger--slider is-active" : "c-navigation__button hamburger hamburger--slider";
    const backgroundClass = background ? "c-navigation--background" : "";
    const componentClassNames = isMobile ? `${backgroundClass} c-navigation--mobile` : `${backgroundClass} c-navigation--desktop`;

    return (
        <div className={`c-navigation ${className}`}>
            <div className={componentClassNames}>
                {isMobile &&
            <button
                className={buttonClassNames}
                type="button"
                aria-label="Menu"
                aria-controls="navigation"
                onClick={() => onToggleMenu()}
            >
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
                {isMenuOpen && <span className="hamburger-label">Close</span>}
                {!isMenuOpen && <span className="hamburger-label">Menu</span>}
            </button>
                }
            </div>
        </div>
    );
};


export const Navigation = (props) => {

    return (
        <StaticQuery
            query={graphql`
            query NavigationQuery {
                site {
                    siteMetadata {
                      supportedLanguageRegions
                      releaseStage
                    }
                }
                allCategory {
                    edges {
                        node {
                            ...Categorys
                        }
                    }
                }
                allSubCategory {
                    edges {
                        node {
                            ...SubCategorys
                        }
                    }
                }
            }
        `}
            render={data => {
                const categories = data && data.allCategory && data.allCategory.edges && data.allCategory.edges && priorityOrder(data.allCategory.edges);
                const subCategories = data && data.allSubCategory && data.allSubCategory.edges && data.allSubCategory.edges && priorityOrder(data.allSubCategory.edges);

                return (
                    <NavigationComponent
                        className={props.className}
                        isMenuOpen={props.isMenuOpen}
                        isMobile={props.isMobile}
                        categories={categories}
                        subCategories={subCategories}
                        languageRegions={data.site.siteMetadata.supportedLanguageRegions}
                        languageRegion={props.languageRegion}
                        background={props.background}
                        darkBackground={props.darkBackground}
                        releaseStage={data.site.siteMetadata.releaseStage}
                    />
                );
            }}
        />
    );
};

// PropTypes
Navigation.propTypes = {
    isMenuOpen: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    className: PropTypes.string,
    categories: PropTypes.object,
    subCategories: PropTypes.object,
    languageRegions: PropTypes.array,
    languageRegion: PropTypes.string,
    background: PropTypes.bool
};

NavigationButton.propTypes = {
    isMenuOpen: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onToggleMenu: PropTypes.func.isRequired,
    background: PropTypes.bool
};
