import React, { useContext, useState } from "react";

import { Link } from "gatsby";
//import PropTypes from 'prop-types';

// CSS
import "./header.scss";

// SVG
//const svgLogo = require('../../../../img/logo/logo.svg');
import { CountrySelector } from "../../components/country-selector";
import { Logo } from "../../components/logo";
import { Navigation, NavigationButton } from "../../components/navigation";
// import { PartnerWebsites } from "../../components/partner-websites";
// Components & Containers
//import Breadcrumbs from '../../components/breadcrumbs';
import { LanguageRegionContext } from "../../context-providers/language-region-context";
// import { Basket } from '../../components/buttons/basket';
// import { Checkout } from '../../components/buttons/checkout';
import { getPath } from "../../helpers/get-path";

export const Header = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { languageRegion, updateLanguageRegion } = useContext(LanguageRegionContext);
    const logoPath = getPath(languageRegion);

    return (
        <header className={props.background && !isMenuOpen ? "c-header c-header--background" : "c-header"}>
            <div className="g-grid__container g-grid__container--header-layout">
                <div className="g-grid__item--partner-pl"></div>
                <div className="g-grid__item--partner-main">
                    {/* <PartnerWebsites languageRegion={languageRegion} /> */}
                </div>
                <div className="g-grid__item--partner-pr"></div>
                <div className="g-grid__item--header-pl-title"></div>
                <div className="g-grid__item--header-main-title">
                    <div className="g-grid__container--mobile-layout">
                        <div className="g-grid__item--mobile-layout-logo c-header__logo--mobile u-margin-bottom--05 u-margin-top--05">
                            <div className="g-flex__item--grow g-flex__container g-flex__container--justify-center g-flex__container--no-wrap">
                                <Link to={logoPath} className="c-header__title g-flex__item">
                                    <Logo containerClassName="g-flex__item" darkBackground={props.darkBackground && !isMenuOpen} />
                                </Link>
                            </div>
                        </div>
                        <div className="g-grid__item--mobile-layout-country c-header__country-selector--mobile">
                            <CountrySelector languageRegion={languageRegion} updateLanguageRegion={updateLanguageRegion} darkBackground={props.darkBackground && !isMenuOpen} />
                        </div>
                        <NavigationButton
                            className={"c-header__navigation--mobile g-grid__item--mobile-layout-navButton u-margin-bottom--05 u-margin-top--05"}
                            isMenuOpen={isMenuOpen}
                            onToggleMenu={() => setIsMenuOpen(!isMenuOpen)}
                            isMobile={true}
                            background={props.background && !isMenuOpen}
                            darkBackground={props.darkBackground}
                        />
                        <Navigation
                            className="g-grid__item--mobile-layout-navMenu"
                            isMenuOpen={isMenuOpen}
                            languageRegion={languageRegion}
                            isMobile={true}
                            background={props.background && !isMenuOpen}
                            darkBackground={props.darkBackground}
                        />
                    </div>

                </div>
                <div className="g-grid__item--header-pr-title"></div>
                <div className="g-grid__item--header-pl"></div>
                <div className="g-grid__item--header-main">

                    <div className="c-header__above-nav">
                        <div className="g-flex__container g-flex__container--align-i-end">
                            <div className="c-header__logo--desktop u-margin-bottom--05 u-margin-top--05 g-flex__item--grow g-flex__container g-flex__container--start">
                                <div className=" g-flex__item--grow g-flex__container g-flex__container--no-wrap">
                                    <Link to={logoPath} className="c-header__title g-flex__item">
                                        <Logo containerClassName="g-flex__item" darkBackground={props.darkBackground && !isMenuOpen} />
                                    </Link>
                                </div>
                            </div>
                            <div className="c-header__shop-buttons u-margin-bottom--05 u-margin-top--05 g-flex__item--grow g-flex__container g-flex__container--end">
                                {/* <Basket />
                <Checkout /> */}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="g-grid__item--header-pr"></div>
                <div className="g-grid__item--header-nav-pl"></div>
                <div className="g-grid__item--header-nav">
                    <div className="c-header__navigation-wrapper">
                        <Navigation languageRegion={languageRegion} isMobile={false} background={props.background && !isMenuOpen} darkBackground={props.darkBackground} />
                    </div>
                </div>
                <div className="g-grid__item--header-nav-pr"></div>
            </div>
        </header>
    );
};

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: '',
// }