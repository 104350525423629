import React from "react";

import { Location } from "@reach/router";
import { graphql, StaticQuery , Link } from "gatsby";

// Circle flags from https://github.com/HatScripts/circle-flags
import circleFlagAU from "../../../data/images/flags/au.svg";
import circleFlagCA from "../../../data/images/flags/ca.svg";
import circleFlagEU from "../../../data/images/flags/european_union.svg";
import circleFlagGB from "../../../data/images/flags/gb.svg";
import circleFlagNZ from "../../../data/images/flags/nz.svg";
import circleFlagUS from "../../../data/images/flags/us.svg";
import { changeLanguageRegionPath } from "../../context-providers/language-region-context";

import "./country-selector.scss";

const getCountryTitle = (languageRegionDataItem) => {
    if (languageRegionDataItem === "en-gb") { return "United Kingdom"; }
    if (languageRegionDataItem === "en-us") { return "United States"; }
    if (languageRegionDataItem === "en-au") { return "Australia"; }
    if (languageRegionDataItem === "en-ca") { return "Canada"; }
    if (languageRegionDataItem === "en-nz") { return "New Zealand"; }
    if (languageRegionDataItem === "en-eu") { return "European Union"; }
};

const getCountryFlag = (languageRegionDataItem) => {
    const code = languageRegionDataItem.slice(-2);

    if (code === "gb") { return (<img className="g-flex__item c-country-selector__flag_image" alt="British flag" src={circleFlagGB} />); }
    if (code === "us") { return (<img className="g-flex__item c-country-selector__flag_image" alt="United States flag" src={circleFlagUS} />); }
    if (code === "au") { return (<img className="g-flex__item c-country-selector__flag_image" alt="Australian flag" src={circleFlagAU} />); }
    if (code === "ca") { return (<img className="g-flex__item c-country-selector__flag_image" alt="Canadian flag" src={circleFlagCA} />); }
    if (code === "nz") { return (<img className="g-flex__item c-country-selector__flag_image" alt="New Zealand flag" src={circleFlagNZ} />); }
    if (code === "eu") { return (<img className="g-flex__item c-country-selector__flag_image" alt="European Union flag" src={circleFlagEU} />); }
};

const getCountryFlagRounded = (languageRegionDataItem) => {
    const code = languageRegionDataItem.slice(-2);

    if (code === "gb") { return { backgroundImage: `url(${circleFlagGB})`, backgroundSize: "35px 35px", backgroundPosition: "center right" }; }
    if (code === "us") { return { backgroundImage: `url(${circleFlagUS})`, backgroundSize: "35px 35px", backgroundPosition: "center right" }; }
    if (code === "au") { return { backgroundImage: `url(${circleFlagAU})`, backgroundSize: "35px 35px", backgroundPosition: "center right" }; }
    if (code === "ca") { return { backgroundImage: `url(${circleFlagCA})`, backgroundSize: "35px 35px", backgroundPosition: "center right" }; }
    if (code === "nz") { return { backgroundImage: `url(${circleFlagNZ})`, backgroundSize: "35px 35px", backgroundPosition: "center right" }; }
    if (code === "eu") { return { backgroundImage: `url(${circleFlagEU})`, backgroundSize: "35px 35px", backgroundPosition: "center right" }; }
};

const CountrySelectorComponent = ({ updateLanguageRegion, languageRegion, supportedLanguageRegions, darkBackground }) => {
    const onChangeSelect = (location, currentLanguageRegion, event) => {
        updateLanguageRegion({ newLanguageRegion: event.target.value, location });
    };

    const selectClassNames = "g-flex__item c-country-selector__select c-title__title";
    const containerClass = darkBackground ? "c-country-selector c-country-selector--background" : "c-country-selector";

    return (
        <Location>
            {({ location }) => {
                return (
                    <div className={`${containerClass} g-flex__item--grow g-flex__container g-flex__container--no-wrap g-flex__container--align-i-center g-flex__container--end`}>
                        <label className="g-flex__item c-country-selector__label u-visually-hidden" htmlFor="country_select">Country:</label>
                        <select id="country_select" dir="rtl" name="Country" className={selectClassNames} style={getCountryFlagRounded(languageRegion)} onChange={(e) => onChangeSelect(location, languageRegion, e)} value={languageRegion}>
                            <option disabled>Select a country</option>
                            {
                                supportedLanguageRegions && supportedLanguageRegions.length > 0 && supportedLanguageRegions.map((languageRegionDataItem) => {
                                    const countryTitle = getCountryTitle(languageRegionDataItem);

                                    return (
                                        <option key={`country-selector-${languageRegionDataItem}`} style={getCountryFlagRounded(languageRegionDataItem)} className="c-country-selector__option" value={languageRegionDataItem}>
                                            {countryTitle}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </div>
                );
            }}

        </Location>

    );
};

const CountrySelectorLinksComponent = ({updateLanguageRegion, languageRegion, supportedLanguageRegions, vertical = false }) => {
    const onClickCountry = (location, newLanguageRegion) => {
        updateLanguageRegion({ newLanguageRegion: newLanguageRegion, location });
    };

    const horizontalClasses = "g-flex__container--column-mobile-only";
    const verticalClasses = "g-flex__container--column-always";
    const containerClasses = vertical ? verticalClasses : horizontalClasses;

    return (
        <ul className={`c-country-selector u-margin-top--1 g-flex__container ${containerClasses}`}>
            {
                supportedLanguageRegions && supportedLanguageRegions.length > 0 && supportedLanguageRegions.map((languageRegionDataItem) => {
                    if (languageRegionDataItem !== languageRegion) {
                        const countryTitle = getCountryTitle(languageRegionDataItem);
                        const countryFlag = getCountryFlag(languageRegionDataItem);

                        return (
                            <Location key={`country-selector-links-location-${languageRegionDataItem}`}>
                                {({ location }) => {
                                    const href = changeLanguageRegionPath({ pathname: location.pathname, newLanguageRegion: languageRegionDataItem, supportedLanguageRegions });
                                    const listItemClasses = vertical ? "g-flex__item c-country-selector-links__li--vertical" : "g-flex__item c-country-selector-links__li";

                                    return (
                                        <li key={`country-selector-links-${languageRegionDataItem}`} className={`u-margin-top--1 ${listItemClasses}`}>
                                            <Link className="u-link--underline g-flex__container g-flex__container--align-i-center" to={`/${href}`} onClick={() => onClickCountry(location, languageRegionDataItem)}>{countryFlag} <span className="g-flex__item u-margin-left--1 g-flex__item">{countryTitle}</span></Link>
                                        </li>
                                    );
                                }}
                            </Location>
                        );
                    }

                    return null;
                })
            }
        </ul>
    );
};

export const CountrySelector = ({ languageRegion, updateLanguageRegion, linksOnly, vertical, background }) => {
    return (
        <StaticQuery
            query={graphql`
            query {
                site {
                    siteMetadata {
                        supportedLanguageRegions
                    }
                }
            }
        `}
            render={data => {
                if (linksOnly) {
                    return <CountrySelectorLinksComponent
                        updateLanguageRegion={updateLanguageRegion}
                        languageRegion={languageRegion}
                        supportedLanguageRegions={data.site.siteMetadata.supportedLanguageRegions}
                        vertical={vertical}
                    />;
                }

                return <CountrySelectorComponent
                    updateLanguageRegion={updateLanguageRegion}
                    languageRegion={languageRegion}
                    supportedLanguageRegions={data.site.siteMetadata.supportedLanguageRegions}
                    background={background}
                />;

            }}
        />
    );
};
