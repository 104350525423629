import React from "react";

import { Footer } from "../../containers/footer";
import { Header } from "../../containers/header";
import { HelmetHelper } from "../../helpers/helmet-helper";

import "./default-layout.scss";

export const DefaultLayout = (props) => {
    return (
        <div className={props.className}>
            <HelmetHelper {...props.pageDetails} />
            {props.background}
            <Header background={props.background ? true : false} darkBackground={props.darkBackground ? true : false} />
            <main>
                {props.children}
            </main>
            <Footer />
        </div>
    );
};

export default DefaultLayout;
