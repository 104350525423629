import React, { useContext } from "react";

import { useStaticQuery, graphql } from "gatsby";

import { CompanyInfo } from "../../components/company-info";
import { Copyright } from "../../components/copyright";
import { CountrySelector } from "../../components/country-selector";
import { ProductsList } from "../../components/products-list";
import { LanguageRegionContext } from "../../context-providers/language-region-context";

import "./footer.scss";

export const Footer = () => {
    const { languageRegion, updateLanguageRegion } = useContext(LanguageRegionContext);

    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        releaseStage
                    }
                }
            }
          `
    );

    const releaseStage = data && data.site && data.site.siteMetadata && data.site.siteMetadata.releaseStage;

    return (
        <footer className="c-footer">
            <div className="g-grid__container--footer-layout">
                <div className="g-grid__item--content-pl"></div>
                <div className="g-grid__item--content-main">
                    <h2 className="u-visually-hidden">Helpful links and information</h2>
                    <div className="c-footer__container g-grid__container--footer">
                        {releaseStage > 2 &&
                            <>
                                <div className="g-grid__item--content-col1">
                                    <h3 id="footer-col1" className="c-footer__list-title primary-font">Products</h3>
                                    <ProductsList props={{ languageRegion: languageRegion }} />
                                    <hr className="c-footer__hr" />
                                </div>
                                <div className="g-grid__item--content-col3">
                                    <h3 id="footer-col3" className="c-footer__list-title primary-font">Company & Information</h3>
                                    <CompanyInfo languageRegion={languageRegion} releaseStage={releaseStage} />
                                    <hr className="c-footer__hr" />
                                </div>
                            </>
                        }
                        {releaseStage === 2 &&
                            <>
                                <div className="g-grid__item--content-col1">
                                    <h3 id="footer-col1" className="c-footer__list-title primary-font">Company & Information</h3>
                                    <CompanyInfo languageRegion={languageRegion} />
                                    <hr className="c-footer__hr" />
                                </div>
                                <div className="g-grid__item--content-col3">
                                </div>
                            </>
                        }
                        <div className="g-grid__item--content-col4">
                            {/* <h3 id="footer-col4" className="c-footer__list-title primary-font">Affiliate Disclosure</h3> */}
                        </div>
                    </div>
                </div>
                <div className="g-grid__item--content-pr"></div>
            </div>
            {languageRegion && updateLanguageRegion &&
                <div className="c-footer__countries-wrapper u-margin-top--2">
                    <div className="g-grid__container--footer-layout">
                        <div className="g-grid__item--content-pl"></div>
                        <div className="g-grid__item--content-main">
                            <h2 className="u-visually-hidden">Countries</h2>
                            <CountrySelector vertical={false} linksOnly={true} languageRegion={languageRegion} updateLanguageRegion={updateLanguageRegion} />
                        </div>
                        <div className="g-grid__item--content-pr"></div>
                    </div>
                </div>
            }
            <Copyright />
        </footer>
    );

};
